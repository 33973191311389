<template>
  <v-container class="mt-5" >
    <div class="Title text-h2 mt-15 text-center" >
      404 Not Found
      <p class="text-h6 my-3">このページは存在しません。</p>
    </div>


  </v-container>
</template>

<script>
  export default {
    name: 'NotFound',
    data: () => ({
     
    }),
  }
</script>


<style scoped>

  .Title > h1 {
    color: gray;
    font-weight: 200;
    border-bottom: 1px solid gray;
  }

</style>
